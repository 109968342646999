@font-face {
  font-family: "Avenir95Black";
  src: url("./fonts/Avenir_95_Black.ttf") format("truetype"),
  url("./fonts/Avenir_95_Black.woff") format("woff"),
  url("./fonts/Avenir_95_Black.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir95Light";
  src: url("./fonts/Avenir-Light.ttf") format("truetype"),
  url("./fonts/Avenir-Light.woff") format("woff"),
  url("./fonts/Avenir-Light.svg") format("svg");
}

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}
